.info-content-wrapper {
  padding: 5%;
  text-align: center;
}

.info-content-first-title {
  color: var(--secondary100);
  font-size: 4.2rem;
  font-weight: 500;
  line-height: 50px;
}

.info-content-second-title {
  color: var(--primary100);
  font-size: 3.5rem;
  font-weight: 600;
}

.info-content-info-text {
  color: var(--primary102);
  font-size: 1rem;
  padding-bottom: 2%;
  line-height: 25px;
}

.info-content-img {
  width: 80%;
}

@media (min-width: 800px) {
  .order-one {
    order: 1;
  }

  .order-two {
    order: 2;
  }
}

@media (min-width: 800px) {
  .info-content-wrapper {
    padding: 2% 5%;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .info-content-first-title {
    font-size: 5.5rem;
    font-weight: 500;
    line-height: 50px;
  }

  .info-content-second-title {
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 80px;
  }

  .info-content-text-wrapper {
    padding: 5%;
    width: 50%;
    max-height: 400;
  }

  .info-content-info-text {
    font-size: 1rem;
    line-height: 25px;
  }

  .info-content-img {
    max-width: 50%;
    object-fit: cover;
    max-height: 400px;
  }
}

@media (min-width: 1400px) {
  .info-content-info-text {
    font-size: 1.3rem;
    line-height: 38px;
  }
}
