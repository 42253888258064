.modal-bg-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);
  z-index: 199;
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 25px;
  padding: 10px;
  box-shadow: 24;
  z-index: 200;
  background-color: var(--font100);

  min-width: 200px;
  width: 80%;
  max-width: 900px;
}

.modal-close-btn {
  position: fixed;
  top: 11%;
  left: 93%;
  transform: translate(-50%, -50%);
}

.modal-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title-text {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  margin: 5px 0;
}

.modal-info-text {
  text-align: center;
  font-size: 1rem;
  padding-bottom: 5px;
  margin: 5px 0;
}

.modal-btn-wrapper {
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 5px;
  border-top: 1px black solid;
}

.modal-btn-wrapper > * {
  margin: 10px 0;
}

@media (min-width: 500px) {
  .modal-wrapper {
    min-width: 200px;
    width: 50%;
    max-width: 500px;
  }
}
