.footer-wrapper {
  background-color: var(--primary101);
  color: var(--font100) !important;

  display: flex;
  flex-direction: column;

  padding: 40px;
}

.contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  font-size: 2rem;

  margin-bottom: 20px;
}

.footer-underline-anchor {
  color: var(--font100);
  padding-bottom: 10px;

  display: inline-block;
  position: relative;
  padding-bottom: 4px;
  border-bottom: 3px var(--font100) solid;
  transition: 0.4s;
}

.footer-underline-anchor:hover {
  border-bottom: 3px var(--secondary100) solid;
}

.footer-underline-anchor-info {
  color: var(--font100);
  padding-bottom: 10px;

  display: inline-block;
  position: relative;
  padding-bottom: 4px;
  border-bottom: 2px var(--font100) solid;
  transition: 0.4s;
}

.footer-underline-anchor-info:hover {
  border-bottom: 2px var(--secondary100) solid;
}

.footer-information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-single-info-wrapper {
  margin: 10px 0;
}

.footer-info-title {
  font-size: 1.8rem;
  font-weight: bold;

  margin: 2% 0;
}

.footer-info {
  margin: 10px 0;
  font-size: 1.2rem;
}

@media (min-width: 900px) {
  .footer-information-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    margin: 1.5% 0;
  }

  .contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    font-size: 2.5rem;
  }

  .footer-single-info-wrapper {
    margin: 0;
  }

  .footer-info-title {
    font-size: 1.7rem;

    margin: 2% 0;
  }

  .footer-info {
    margin: 5px 0;
    font-size: 1.2rem;
  }
}
