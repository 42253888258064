.menu-btn {
  display: inline-block;
  position: relative;
  color: var(--secondary100);
  padding-bottom: 4px;

  margin-bottom: 20px;
}

.menu-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--secondary100);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.menu-btn:hover::after {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.third-info-btn-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 2%;
}

.third-info-btn {
  color: var(--font100);
  margin: 2% 0 5% 0;
  font-size: 2rem;
  cursor: pointer;

  color: #fff;
  border: 2px solid var(--primary101);
  border-radius: 0px;
  padding: 14px 36px;
  display: inline-block;
  box-shadow: inset 400px 0 0 0 var(--primary101);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.third-info-btn-a {
  color: inherit;
}

.third-info-btn-slide:hover {
  box-shadow: inset 0 0 0 0 var(--primary101);
  color: var(--primary101);
}

@media (min-width: 1400px) {
  .menu-btn {
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}
