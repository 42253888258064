.schedule-navbar-wrapper {
  background-color: var(--primary102);
  color: var(--font100);
  width: 100%;
  height: 80px;

  font-size: 1rem;

  display: flex;
  align-items: center;
  text-align: center;

  position: fixed;
  top: 0;
  z-index: 100;
}

.schedule-navbar-text {
  width: 87%;
  text-align: center;
}

.schedule-navbar-btn {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

@media (min-width: 769px) {
  .schedule-spacer {
    height: 52px;
  }

  .schedule-navbar-text {
    width: 95%;
  }

  .schedule-navbar-wrapper {
    height: 52px;
  }
}
