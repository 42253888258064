.main-order-btn-wrapper {
  width: 100%;
  position: fixed;
  bottom: 25px;
  flex-direction: column;
  align-items: center;

  display: flex;
  justify-content: center;
  z-index: 100;
}

.scroll-down-wrapper {
  position: relative;
  display: flex;
  justify-content: center;

  cursor: pointer;
}

.scroll-down-symbol {
  position: absolute;
  top: 5px;

  font-weight: 600;
}

@media (min-width: 778px) {
  .main-order-btn-wrapper > * {
    width: 290px;
  }
}
