@import url("https://fonts.googleapis.com/css2?family=Tinos:wght@400;500;600;900&display=swap");

* {
  margin: 0;
  font-family: "Tinos", sans-serif;
  --primary100: #34394b;
  --primary101: #212b49;
  --primary102: #39497c;
  --secondary100: #c08c34;
  --font100: white;
  --font200: black;
}
