.information-img-wrapper {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 50%
    ),
    url("../../../assets/images/informationImg1.png");

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 400px;

  color: white;
}

.information-img-text-wrapper {
  padding: 5%;
  text-align: center;
}

.information-img-first-title {
  color: var(--secondary100);
  font-size: 4rem;
  font-weight: 500;
  line-height: 50px;
}

.information-img-second-title {
  color: var(--font100);
  font-size: 3.5rem;
  font-weight: 600;
}

@media (min-width: 778px) {
  .information-img-wrapper {
    display: flex;
    justify-content: center;
    height: 60vh;
  }

  .information-img-first-title {
    font-size: 7rem;
    line-height: 50px;
  }

  .information-img-second-title {
    color: var(--font100);
    font-size: 6rem;
  }
}

@media only screen and (max-device-width: 1px) {
  .information-img-wrapper {
    background-attachment: scroll;
  }
}
