.hero-img-wrapper {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%
    ),
    url("../../assets/images/heroImg.png");

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;

  color: white;
}

.hero-title {
  font-size: 3rem;
}

.hero-text {
  font-size: 1.2rem;
  flex-wrap: wrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hero-text > * {
  margin-left: 10px;
}

.hero-text-year {
  font-size: 1rem;
  margin: 5px 15px;
}

@media (min-width: 778px) {
  .hero-title {
    font-size: 6rem;
    width: 100%;
  }

  .hero-text {
    font-size: 2rem;
    width: 100%;
  }

  .hero-text-year {
    font-size: 1.4rem;
  }
}

@media only screen and (max-device-width: 1px) {
  .hero-img-wrapper {
    background-attachment: scroll;
  }
}
