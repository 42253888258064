.round-btn-wrapper {
  background-color: var(--secondary100);
  width: 90%;
  height: 42px;
  cursor: pointer;
  border: 0;
  text-decoration: none;

  border-radius: 50px;

  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
}

.round-btn-text {
  color: var(--font100);
  background-color: transparent;
  border: none;
  cursor: pointer;

  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
}
